// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-demo-index-tsx": () => import("./../../../src/pages/demo/index.tsx" /* webpackChunkName: "component---src-pages-demo-index-tsx" */),
  "component---src-pages-families-index-tsx": () => import("./../../../src/pages/families/index.tsx" /* webpackChunkName: "component---src-pages-families-index-tsx" */),
  "component---src-pages-faq-for-families-index-tsx": () => import("./../../../src/pages/faq-for-families/index.tsx" /* webpackChunkName: "component---src-pages-faq-for-families-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-for-administrators-index-tsx": () => import("./../../../src/pages/for-administrators/index.tsx" /* webpackChunkName: "component---src-pages-for-administrators-index-tsx" */),
  "component---src-pages-for-teachers-index-tsx": () => import("./../../../src/pages/for-teachers/index.tsx" /* webpackChunkName: "component---src-pages-for-teachers-index-tsx" */),
  "component---src-pages-for-tutors-index-tsx": () => import("./../../../src/pages/for-tutors/index.tsx" /* webpackChunkName: "component---src-pages-for-tutors-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-how-yup-works-index-tsx": () => import("./../../../src/pages/how-yup-works/index.tsx" /* webpackChunkName: "component---src-pages-how-yup-works-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-index-tsx": () => import("./../../../src/pages/legal/index.tsx" /* webpackChunkName: "component---src-pages-legal-index-tsx" */),
  "component---src-pages-math-resource-pages-index-tsx": () => import("./../../../src/pages/math-resource-pages/index.tsx" /* webpackChunkName: "component---src-pages-math-resource-pages-index-tsx" */),
  "component---src-pages-new-page-template-index-tsx": () => import("./../../../src/pages/new-page-template/index.tsx" /* webpackChunkName: "component---src-pages-new-page-template-index-tsx" */),
  "component---src-pages-our-tutors-index-tsx": () => import("./../../../src/pages/our-tutors/index.tsx" /* webpackChunkName: "component---src-pages-our-tutors-index-tsx" */),
  "component---src-pages-plans-index-tsx": () => import("./../../../src/pages/plans/index.tsx" /* webpackChunkName: "component---src-pages-plans-index-tsx" */),
  "component---src-pages-press-index-tsx": () => import("./../../../src/pages/press/index.tsx" /* webpackChunkName: "component---src-pages-press-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-purchase-complete-index-tsx": () => import("./../../../src/pages/purchase-complete/index.tsx" /* webpackChunkName: "component---src-pages-purchase-complete-index-tsx" */),
  "component---src-pages-purchase-forms-payer-form-tsx": () => import("./../../../src/pages/purchase/forms/PayerForm.tsx" /* webpackChunkName: "component---src-pages-purchase-forms-payer-form-tsx" */),
  "component---src-pages-purchase-forms-payment-form-tsx": () => import("./../../../src/pages/purchase/forms/PaymentForm.tsx" /* webpackChunkName: "component---src-pages-purchase-forms-payment-form-tsx" */),
  "component---src-pages-purchase-forms-section-tsx": () => import("./../../../src/pages/purchase/forms/Section.tsx" /* webpackChunkName: "component---src-pages-purchase-forms-section-tsx" */),
  "component---src-pages-purchase-forms-student-form-tsx": () => import("./../../../src/pages/purchase/forms/StudentForm.tsx" /* webpackChunkName: "component---src-pages-purchase-forms-student-form-tsx" */),
  "component---src-pages-purchase-index-tsx": () => import("./../../../src/pages/purchase/index.tsx" /* webpackChunkName: "component---src-pages-purchase-index-tsx" */),
  "component---src-pages-review-purchase-index-tsx": () => import("./../../../src/pages/review-purchase/index.tsx" /* webpackChunkName: "component---src-pages-review-purchase-index-tsx" */),
  "component---src-pages-reviews-index-tsx": () => import("./../../../src/pages/reviews/index.tsx" /* webpackChunkName: "component---src-pages-reviews-index-tsx" */),
  "component---src-pages-schedule-demo-index-tsx": () => import("./../../../src/pages/schedule-demo/index.tsx" /* webpackChunkName: "component---src-pages-schedule-demo-index-tsx" */),
  "component---src-pages-school-day-integrations-index-tsx": () => import("./../../../src/pages/school-day-integrations/index.tsx" /* webpackChunkName: "component---src-pages-school-day-integrations-index-tsx" */),
  "component---src-pages-schools-success-index-tsx": () => import("./../../../src/pages/schools-success/index.tsx" /* webpackChunkName: "component---src-pages-schools-success-index-tsx" */),
  "component---src-pages-sendme-index-tsx": () => import("./../../../src/pages/sendme/index.tsx" /* webpackChunkName: "component---src-pages-sendme-index-tsx" */),
  "component---src-pages-subjects-we-teach-index-tsx": () => import("./../../../src/pages/subjects-we-teach/index.tsx" /* webpackChunkName: "component---src-pages-subjects-we-teach-index-tsx" */),
  "component---src-pages-teacher-announcements-index-tsx": () => import("./../../../src/pages/teacher-announcements/index.tsx" /* webpackChunkName: "component---src-pages-teacher-announcements-index-tsx" */),
  "component---src-pages-teacher-hub-index-tsx": () => import("./../../../src/pages/teacher-hub/index.tsx" /* webpackChunkName: "component---src-pages-teacher-hub-index-tsx" */),
  "component---src-pages-teaching-framework-index-tsx": () => import("./../../../src/pages/teaching-framework/index.tsx" /* webpackChunkName: "component---src-pages-teaching-framework-index-tsx" */),
  "component---src-pages-tutor-onboarding-index-tsx": () => import("./../../../src/pages/tutor-onboarding/index.tsx" /* webpackChunkName: "component---src-pages-tutor-onboarding-index-tsx" */),
  "component---src-pages-videos-and-media-index-tsx": () => import("./../../../src/pages/videos-and-media/index.tsx" /* webpackChunkName: "component---src-pages-videos-and-media-index-tsx" */),
  "component---src-pages-webinar-recording-april-2022-index-tsx": () => import("./../../../src/pages/webinar-recording-april-2022/index.tsx" /* webpackChunkName: "component---src-pages-webinar-recording-april-2022-index-tsx" */),
  "component---src-pages-webinar-recording-index-tsx": () => import("./../../../src/pages/webinar-recording/index.tsx" /* webpackChunkName: "component---src-pages-webinar-recording-index-tsx" */),
  "component---src-pages-webinar-recording-march-2022-index-tsx": () => import("./../../../src/pages/webinar-recording-march-2022/index.tsx" /* webpackChunkName: "component---src-pages-webinar-recording-march-2022-index-tsx" */),
  "component---src-pages-webinar-recording-may-2022-index-tsx": () => import("./../../../src/pages/webinar-recording-may-2022/index.tsx" /* webpackChunkName: "component---src-pages-webinar-recording-may-2022-index-tsx" */),
  "component---src-templates-announcement-js": () => import("./../../../src/templates/announcement.js" /* webpackChunkName: "component---src-templates-announcement-js" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-math-resource-js": () => import("./../../../src/templates/math-resource.js" /* webpackChunkName: "component---src-templates-math-resource-js" */),
  "component---src-templates-math-subject-js": () => import("./../../../src/templates/math-subject.js" /* webpackChunkName: "component---src-templates-math-subject-js" */),
  "component---src-templates-videos-and-media-tsx": () => import("./../../../src/templates/videos-and-media.tsx" /* webpackChunkName: "component---src-templates-videos-and-media-tsx" */)
}

